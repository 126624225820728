@mixin listWrapper {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0;
  width: 100%;
  background: white;
}

@mixin listGrid {
  position: absolute;
  top: 50px;
  left: 0px;
  width: 100%;
  height: calc(100% - 52px);
}