@import "_mediaQueriesConstants";
@import "_mixins.scss";
@import "_footer.scss";
@import "_navbar.scss";
@import "_form.scss";
@import "_board-table.scss";
@import "_reactTable.scss";
@import "_pagination.scss";
@import "_progressBar.scss";
@import "page404.scss";

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  *:focus {
    outline: none;
  }
}

.full-width-loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
}

.error {
  color: red;
  width: 100%;
  text-align: center;
  padding-top: 10px;
}

#root {
  #root-child {

    #content {

      @media #{$tablet-and-phone} {
        height: calc(100vh - #{$footer-mobile-height} - #{$navbar-mobile-height});
      }
      height: calc(100vh - #{$footer-height} - #{$navbar-height});

      .content-wrapper {
        height: 100%;
        overflow: auto;
        position: relative;

        .charger-details-snackbar {
          bottom: 10vh !important;
          text-align: center;
        }
      }
    }
  }

  #main {
    height: 100%;
    display: table-row;
    position: relative;
    background: #F2F5F8;
  }
}

.list-wrapper {
  @include listWrapper;
  .board-table {
    @include listGrid;
    .ReactTable {
      height: 100%;
      font-size: 14px;
      font-family: Roboto, sans-serif;
      .date-input-filter {
        width: 100%;
        height: 28px !important;
      }
      .rt-td:first-letter {
        text-transform: capitalize;
      }
    }
  }
}

.dictionary-toolbar {
  padding-left: 0 !important;
  background-color: #fff !important;
  height: 50px !important;
  .table-button, .table-button-with-span {
    margin-right: 0 !important;
    margin-left: 10px !important;
    overflow: hidden;
  }
  .title {
    color: black;
    font-size: 16px;
    font-family: Roboto, sans-serif;
  }
}

.rt-thead {
  background-color: #FAFAFA;

  .rt-resizable-header-content {
    padding: 5px 0;
    font-size: 13px;
  }
}

.rt-td {
  font-size: 13px;
  line-height: 25px;
  display: flex;
  align-items: center;
}

.rt-td.nested-table {
  padding: 0;
  .nested-rows-container{
    width: 100%;
    .nested-row {
      padding: 5px;
      border-bottom: solid 1px rgba(0,0,0,0.05);
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .nested-row:last-child {
      border-bottom: 0;
    }
  }
}

.ReactTable {
  height: 100%;
  .rt-table {
    position: relative;

    .rt-thead {
      &.-header {
        box-shadow: none;
        position: sticky;
        top: 0;
        height: 36px;
        z-index: 1;
        .rt-tr {
          height: 36px;
        }
      }
      &.-filters {
        position: sticky;
        top: 36px;
        z-index: 1;
        height: 40px;
      }
      .rt-tr {
        text-align: left;
      }
    }

    .rt-tbody {
      overflow: visible;
    }

    .break-line-cell {
      word-wrap: break-word;
      white-space: initial;
    }
  }
}
