.rt-thead {
  background-color: #FAFAFA;

  .rt-resizable-header-content {
    padding: 5px 0;
    font-size: 13px;
  }
}

.rt-td {
  font-size: 13px;
  line-height: 25px;
  display: flex;
  align-items: center;
}

.rt-td.nested-table {
  padding: 0;
  .nested-rows-container {
    width: 100%;
    .nested-row {
      padding: 5px;
      border-bottom: solid 1px rgba(0, 0, 0, 0.05);
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .nested-row:last-child {
      border-bottom: 0;
    }
  }
}

.ReactTable {
  height: 100%;
  border: none;
  outline: solid 1px rgba(0, 0, 0, 0.1);

  @media #{$tablet-and-phone} {
    outline-offset: calc(#{$pagination-height} + 1px);
    margin-top: #{$pagination-height};
    height: calc(100vh - #{$pagination-height} - #{$footer-mobile-height} - #{$navbar-mobile-height});
    font-size: 14px;

    .rt-table {
      bottom: #{$pagination-height}
    }
    .rt-td:first-letter {
      text-transform: capitalize;
    }
    .rt-noData {
      top: 20%;
    }

    @media #{$tablet-and-phone} {
      .pagination-bottom {
        position: fixed;
        bottom: #{$footer-mobile-height};
        left: 0;
        right: 0;
        background: white;
      }
    }

  }

  .rt-table {
    position: relative;

    .rt-thead {
      &.-header {
        box-shadow: none;
        position: sticky;
        top: 0;
        height: 36px;
        z-index: 1;
        .rt-tr {
          height: 36px;
        }
      }
      &.-filters {
        position: sticky;
        top: 35px;
        z-index: 1;
        height: 40px;
      }
      .rt-tr {
        text-align: left;
      }
    }

    .rt-tbody {
      overflow: visible;
    }

    .break-line-cell {
      word-wrap: break-word;
      white-space: initial;
    }
  }

}

.sub-component {
  .rt-thead {
    background-color: #ffffff;
  }
  padding: 5px 20px 20px 20px;
  background-color: rgba(184, 184, 184, 0.12);
  box-shadow: inset 0px 11px 8px -10px #ccc, inset 0px -11px 8px -10px #ccc;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: top 200ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 200ms ease-in;
}

.fade-appear {
  opacity: 0.01;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.sub-table-wrapper {
  display: flex;
  > div {
    flex-grow: 1;
    margin: 5px;
  }
}

.certificates-cell-wrapper {
  justify-content: space-evenly;
  width:100%;
  display: flex;

  .certificate-link {
    display: inline-block;
    color: #191a1b;
    overflow: hidden;
    text-overflow: ellipsis;
    border: solid 1px #22222229;
    border-radius: 3px;
    align-items: center;
    padding: 0px 2px;
    background-color: #22222205;
    width: 100px;
    line-height: 22px;
    text-decoration: none;

    &:link {
      text-decoration: none;
    }
    &:visited {
      text-decoration: none;
    }

    img {
      margin-bottom: -4px;
      margin-right: 2px;
    }
  }
}

.filter-picker {
  .DateInput_input__small {
    line-height: 18px !important;
    padding: 4px 7px !important;
    border: none !important;
    font-size: 13px !important;
    font-family: Roboto;
  }
  .DateRangePickerInput_clearDates {
    padding-top: 9px !important;
    svg {
      color: #757575 !important;
    }
  }
}