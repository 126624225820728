.nav-bar  {

  @media #{$tablet-and-phone} {
    background: #034157; /* For browsers that do not support gradients or mobile devices (theme)*/
    height: $footer-height;
    padding: 0px;
    // Didn't find another way to affect sandwich button
    // TODO fix if possible (maybe on material-ui version update)
    & > div:first-of-type{
      margin:0px 0px 0px -20px!important;
    }
    & > h1:first-of-type{
      height: $navbar-mobile-height!important;
      line-height: $navbar-mobile-height!important;
    }
  }
  background: -webkit-linear-gradient(to right, #024d66, #3d9275); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(to right, #024d66, #3d9275); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(to right, #024d66, #3d9275); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #024d66, #3d9275); /* Standard syntax */

  .vendor-logo {
    @media #{$tablet-and-phone} {
      font-size: 17px;
      margin: 10px 5px 5px 5px;
    }
    margin: 5px 5px 5px 25px;
    color: white;
    font-size: 25px;
    font-family: Monoton, Roboto;
  }

  .navbar-logo-container {
    min-width: 200px;
    height: 47px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    .logo-img {
      max-width: 200px;
      max-height: 47px;
    }
  }
  .logo-mobile {
    width: 80%;
    margin: 10%;
  }

  .navbar_button {
    height: #{$navbar-height} !important;
    line-height: #{$navbar-height} !important;
    color: rgba(255, 255, 255, 0.7) !important;
    &.selected {
      background-color: rgba(153, 153, 153, 0.2)!important;
    }
    &:hover {
      background-color: rgba(153, 153, 153, 0.3)!important;
      color: rgb(255, 255, 255) !important;
    }
  }

  .greetings {
    margin-right: 10px;
    color: white;
  }
}
.nav-center {
  position: absolute;
  left: 300px;
  right: 300px;
  display: flex;
}
.nav-tabs {
  width: 300px;
  >div a div:hover {
    color: white;
  }
}

.powered-by-container {
  width: 200px;
  height: 48px;
  display: flex;
  div.text {
    line-height: 50px;
    font-family: Roboto;
    font-weight: bold;
    color: white;
    width: 90px;
  }
  img {
    width: 78px;
  }
}

.iotecha-logo {
  background-image: url("../images/iotecha-logo.svg");
}

.navbar-right {
  display: flex;
  margin-top: -13px;
  margin-right: -7px;
  & .navbar_button{
    color: rgb(255, 255, 255)!important;
  }

  .authenticated-wrapper {
    display: flex;
    align-items: center;
    color: white;

  }
}

.capitalize-tabs {
  a, button {
    text-transform: capitalize !important;
  }
  button:hover {
    color: white !important;
  }
}

.selectedNavbarMenuItem {
  background-color: rgba(0, 0, 0, 0.2)!important;
}
