.list-wrapper {
  @include listWrapper;

  .chargers-table {
    @include listGrid;

    .ReactTable {
      height: 100%;
      font-size: 14px;
      font-family: Roboto, sans-serif;

      .date-input-filter {
        width: 100%;
        height: 28px !important;
      }

      .rt-td:first-letter {
        text-transform: capitalize;
      }

      .-filters {
        //background-color: #024d66;
      }
    }
  }
}

.toolbar {
  padding-left: 0 !important;
  background-color: #fff !important;
  height: 50px !important;
  .table-button, .table-button-with-span {
    margin-right: 0 !important;
    margin-left: 10px !important;
    overflow: hidden;
  }
  .title {
    color: black;
    font-size: 16px;
    font-family: Roboto, sans-serif;
  }
}

.charger-details-tabs {
  button {
    white-space: normal;
  }

}

.create-edit-static-list {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100vh - 228px);
  .ReactTable {
    height: 100%;
    font-size: 14px;
    font-family: Roboto, sans-serif;
    .rt-td:first-letter {
      text-transform: capitalize;
    }
  }
}
.create-edit-static-list.mobile {
  height: 500px !important;
}

.create-edit-static-list.mobile.modal {
  .ReactTable {
    height: 500px !important;
  }
}

.table-material-text {
  font-size: 13px !important;
  height: 28px !important;
  background-color: white !important;
  hr {
    bottom: 0 !important;
    left: 1px !important;
    right: 1px !important;
    width: auto !important;
    border-radius: 5px !important;
  }
  input{
    border: 1px solid rgba(0,0,0,0.1) !important;
    border-radius: 3px !important;
    padding-left: 5px !important;
    color: rgba(0,0,0,0.8) !important;
  }
  &.date-input {
    cursor: pointer!important;
    hr[aria-hidden=true] {
      display: none;
    }
  }
}

.snackbar {
  bottom: 10vh !important;
  text-align: center;
}

.board-info-paper {
  overflow: hidden;
  padding: 15px;
  background-color: #fff;
  min-height: calc(100vh - 260px);
  h3 {
    font-size: 22px;
    font-weight: normal;
    margin: 0 0 10px 0;
  }
}

.error-message-instead-table {
  text-align: center;
  padding: 20px 0;
}
