.progress_container {
  background-color: rgba(0, 0, 0, 0.54);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;

  .progress_inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 3px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 45px, rgba(0, 0, 0, 0.22) 0px 10px 18px;

    .progress_label {
      padding: 10px;
      text-align: center;
     }
  }
}