.-pagination {
  align-items: center;
  color: #5B5B5B;
  font-size: 14px;
  padding: 3px 25px !important;
  font-family: Roboto, sans-serif;

  .MuiFormControl-root {
    margin-bottom: 5px !important;
  }

  .MuiSelect-root {
    font-size: 14px;
    color: #5b5b5b;
    padding: 10px 10px 5px 10px;
  }

  .MuiSelect-select:focus {
    background: transparent;
  }

  @media (max-width: 375px) {
    font-size: 12px;
  }

  @media (max-width: 320px) {
    font-size: 10px;
  }

  &>div {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .-pageSizeOptions {
    display: flex;
    align-items: center;
    flex-basis: 41%;
    padding: 0;
    margin: 0 !important;

    .pageSizeSelect {
      width: 80px!important;

      @media (max-width: 320px) {
        width: 70px !important;
      }

      &>div>div>div {
        color: #5B5B5B!important;
        font-size: 14px;

        @media (max-width: 375px) {
          font-size: 12px;
        }

        @media (max-width: 320px) {
          font-size: 10px;
        }
      }
    }
    .pageSizeSelect.mobile {
      width: 45px!important;
      &>div>div>div {
        overflow: visible !important;
        @media (max-device-width: 1224px) {
          padding-right: 22px !important;
        }
      }
    }
  }

  .-pageJumpBlock {
    flex-basis: 59%;
    justify-content: flex-end;
    button {
      padding: 0 !important;
      width: 35px !important;
      @media (max-device-width: 1224px) {
        width: 30px !important;
      }
    }
  }
}

.-pagination.dashboard-customized {
  justify-content: flex-end;
  .-pageJumpBlock, .-pageSizeOptions {
    flex-basis: unset;
  }
}

@media (max-device-width: 1224px) {
  .-pagination {
    padding: 3px 5px !important;
  }
}
