.date-picker-container {
  position: relative !important;
  .calendarIcon {
    opacity: 0.65 !important;
    position: absolute !important;
    right: 0 !important;
    top: 22px !important;
  }
  .hidden-datePicker {
    display: none;
  }
}

.autocompleteWrapper {
  &>div:focus {
    outline: none;
  }
}

.floatingLabel {
  position: absolute;
  line-height: 22px;
  top: 20px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  z-index: 1;
  transform: scale(0.75) translate(0px, -28px);
  transform-origin: left top 0px;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.298039);
  -webkit-user-select: none;
  font-size: 16px;
}

.error-message {
  position: relative;
  bottom: 2px;
  font-size: 12px;
  line-height: 12px;
  color: rgb(244, 67, 54);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.autocompleteWrapper.disabled {
  div > hr:first-child {
    border-top: none rgba(0, 0, 0, 0.3) !important;
    border-left: none rgba(0, 0, 0, 0.3) !important;
    border-right: none rgba(0, 0, 0, 0.3) !important;
    border-bottom: 2px dotted rgba(0, 0, 0, 0.3) !important;
  }
  div > hr:nth-child(2) {
    display: none;
  }
  svg {
    fill: rgba(0, 0, 0, 0.3) !important;
  }
}
.autocompleteWrapper {
  display: flex;
  
  div {
    font-family: Roboto, sans-serif;
  }

  svg {
    fill: black !important;
    transform: none !important;
  }

  .resetOwnerButton {
    margin-top: 30px!important;
    padding: 0!important;
    flex-shrink: 0;
    margin-right: -20px!important;
    width: 42px !important;
    height: 42px !important;
  }
}

.cursor-auto {
  cursor: auto !important;
}

.date-picker-filter-container {
  position: relative !important;
  .calendarIcon {
    height: 27px !important;
    width: 27px !important;
    padding: 0 !important;
    position: absolute !important;
    right: 0;
    top: 0px;
    svg {
      color: rgb(117, 117, 117) !important;
    }
  }
  .hidden-datePicker {
    width: 0px !important;
    height: 0px !important;
  }
}

.filter-gray-icon {
  color: rgb(117, 117, 117) !important;
}
